<template>
<div>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md12>
                <v-img max-width="40" class="mx-auto" :src="require('@/assets/img/assists/phone.png')" />
            </v-flex>
            <v-flex xs12 md12 mt-3>
                <p class="naranja font-20 font-weight-bold subrayado text-center">
                    Mi Línea de Ayuda
                </p>
            </v-flex>
        </v-layout>
        <p class="azul font-20 text-center">
            Te brindamos orientación vía telefónica en caso de que necesites asesoría legal,<br>
            apoyo escolar, enlace con más servicios y en casos de emergencia.
        </p>
        <v-layout wrap>
            <v-flex xs12 md7 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs12 md5 mt-2 class="offset-md-2">
                        <ul>
                            <li class="azul">Línea legal</li>
                            <li class="azul">Línea escolar</li>
                            <li class="azul">Enlace para emergencias</li>
                            <li class="azul">Plomero</li>
                        </ul>
                    </v-flex>
                    <v-flex xs12 md5 mt-2>
                        <ul>
                            <li class="azul">Electricista</li>
                            <li class="azul">Cerrajero</li>
                            <li class="azul">Vidriero</li>
                        </ul>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    name: "Informacion"
}
</script>

<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/assists/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-8></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/Assits.png')" />
    </v-container>
    <Informacion />
    <v-container mt-5 grid-list-xl>
        <v-layout wrap>
            <v-flex xs12 md11 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs12 md5 class="mx-auto">
                        <p class="azul font-18 text-center mt-5">
                            Si contrataste tu Protección Assist en el área de cajas, debes terminar tu registro colocando
                            los siguientes datos.
                        </p>
                        <v-form v-model="valid" ref="formClienteAssist" v-on:submit.prevent="">
                            <v-text-field v-model="numeroTienda" color="#0C55A6" :rules="reglas.requerido" label="Número tienda Chedraui" outlined dense></v-text-field>
                            <v-text-field v-model="celular" v-mask="'##########'" counter="10" color="#0C55A6" :rules="reglas.requerido" label="Celular" outlined dense></v-text-field>
                            <v-text-field v-model="fechaCompra" color="#0C55A6" v-mask="'##-##-####'" placeholder="dd-mm-aaaa" :rules="reglas.requerido" label="Fecha de compra" outlined dense></v-text-field>
                            <v-btn @click="validaCompra()" depressed small block dark color="#0C55A6">
                                Verifica mi número celular
                            </v-btn>
                        </v-form>
                    </v-flex>
                    <!--<v-flex xs12 md4 class="mx-auto offset-md-3">
                        <p class="azul font-18 text-center mt-5">
                            Si ya contrataste tu Protección Assist y deseas cancelar tu pago recurrente verifica tu ID cliente
                        </p>
                        <v-form v-model="valid" ref="formCancela" v-on:submit.prevent="">
                            <v-text-field v-model="idCliente" color="#0C55A6" :rules="reglas.requerido" label="ID cliente" outlined dense></v-text-field>
                            <v-btn @click="validaCancelacion()" depressed small block dark color="#0C55A6">
                                Verificar mi ID cliente
                            </v-btn>
                            <br>
                            <v-btn @click="confirmCancelacion()" v-if="btnCancelaPago" depressed small block dark color="#FCBD3D">
                                Cancelar suscripción de pagos
                            </v-btn>
                        </v-form>
                    </v-flex>-->
                </v-layout>
            </v-flex>
        </v-layout>
        <!--<v-layout class="mt-3">
            <v-flex xs12 md8 class="mx-auto">
                <p class="naranja text-center font-20">
                    Recuerda que puedes contratar este producto para otra persona, dale clic en "Quiero contratarlo" desde el menú inicial.
                </p>
            </v-flex>
        </v-layout>-->
    </v-container>
    <v-container grid-list-xl v-if="existe">
        <v-card outlined>
            <v-container>
                <p class="azul font-22 text-center">
                    Completa el formulario y obten tu Proteccción Assist
                </p>
                <v-form v-model="valid" ref="formClienteNuevo" v-on:submit.prevent="">
                    <v-layout wrap>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.parentesco" dense label="¿Para quién quieres contratar este servicio?" placeholder="Por ejemplo: Para mi, Para mi primo, Para mi mama, etc." counter="80" outlined :rules="reglas.campo"></v-text-field>
                            <v-text-field v-model="cliente.nombres" color="#0C55A6" :rules="reglas.campo" counter="80" label="Nombre(s)" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.apPat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido paterno" outlined dense></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.apMat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido materno" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.correo" color="#0C55A6" :rules="reglas.correo" counter="150" label="Correo" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.rfc" color="#0C55A6" :rules="reglas.rfc" counter="13" label="RFC" outlined dense></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.celular" color="#0C55A6" v-mask="'##########'" :rules="reglas.telefono" counter="10" label="Teléfono" outlined dense></v-text-field>
                            <v-select v-model="cliente.genero" :items="['Masculino', 'Femenino']" dense label="Género" outlined :rules="reglas.requerido"></v-select>
                            <v-text-field v-model="cliente.fechaNacimiento" v-mask="'####-##-##'" placeholder="aaaa-mm-dd" color="#0C55A6" :rules="reglas.requerido" label="Fecha de nacimiento" outlined dense></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
                <v-layout>
                    <v-flex xs12 md5 class="mx-auto">
                        <v-btn @click="generaReferenciaPago()" depressed small dark block class="btnNoMayuscula" color="#0C55A6">
                            Registrar datos
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-container>
    <Footer programa="Assist" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";
import Informacion from "@/components/assist/Informacion.vue";
import axios from "axios";
import {
    backend
} from "@/back/global.js";

export default {
    name: "Clienteassist",
    components: {
        Footer,
        Informacion
    },
    data: () => ({
        valid: true,
        btnCancelaPago: false,
        urlCancelaPago: "",
        celular: "",
        idCliente: "",
        numeroTienda: "",
        fechaCompra: "",
        existe: false,
        ticket: {},
        cliente: {
            parentesco: "",
            nombres: "",
            apPat: "",
            apMat: "",
            celular: "",
            correo: "",
            fechaNacimiento: "",
            rfc: "",
            genero: ""
        },
        reglas: {
            rfc: [
                value => !!value || "Campo requerido.",
                value => value.length <= 13 || "El campo no puede ser mayor a 13 caracteres."
            ],
            requerido: [
                value => !!value || "Campo requerido.",
            ],
            campo: [
                value => !!value || "Campo requerido.",
                value => value.length <= 80 || "El campo no puede ser mayor a 80 caracteres."
            ],
            correo: [
                value => !!value || "Campo requerido.",
                value => value.length <= 150 || "El campo no puede ser mayor a 150 caracteres."
            ],
            telefono: [
                value => !!value || "Campo requerido."
            ]
        }
    }),
    methods: {
        confirmCancelacion() {
            this.$snotify.warning('Tu producto te cuesta solo DOS PESOS AL MES y cuentas con 7 servicios para ti y tu familia. ¡Usa hoy mismo tus servicios!', '¿Estás seguro que no quieres seguir protegido?', {
                timeout: 50000,
                titleMaxLength: 100,
                bodyMaxLength: 300,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                position: "centerCenter",
                buttons: [{
                        text: 'Si, cancelar',
                        action: (toast) => {
                            location.href = this.urlCancelaPago;
                        }
                    },
                    {
                        text: 'Cerrar ventana',
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                        },
                        bold: true
                    },
                ]
            });
        },
        validaCancelacion() {
            if (!this.$refs.formCancela.validate()) {
                return false;
            }
            this.existe = false;
            this.btnCancelaPago = false;
            this.urlCancelaPago = "";
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "assit/urlCancelacion",
                    headers: backend.headers,
                    data: {
                        idCliente: this.idCliente
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            this.btnCancelaPago = true;
                            this.urlCancelaPago = token.data.url;
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos verificar tu idCliente: ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        validaCompra() {
            if (!this.$refs.formClienteAssist.validate()) {
                return false;
            }

            this.existe = false;
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "assit/validaTicketCompra",
                    headers: backend.headers,
                    data: {
                        celular: this.celular,
                        numeroTienda: this.numeroTienda,
                        fechaCompra: this.fechaCompra
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            if (token.data.cobroInicial == 1) {
                                this.$snotify.error("Tu periodo de contratación ya finalizo.", "Aviso", {
                                    timeout: 3000,
                                    showProgressBar: true,
                                    position: "rightTop"
                                });
                            } else {
                                this.existe = true;
                                this.ticket = token.data;
                            }
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos verificar tu número celular: ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        generaReferenciaPago() {
            if (!this.$refs.formClienteNuevo.validate()) {
                return false;
            }
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "assit/registraClienteTiendaAssist",
                    headers: backend.headers,
                    data: {
                        parentesco: this.cliente.parentesco,
                        nombres: this.cliente.nombres,
                        apPat: this.cliente.apPat,
                        apMat: this.cliente.apMat,
                        celular: this.cliente.celular,
                        correo: this.cliente.correo,
                        fechaNacimiento: this.cliente.fechaNacimiento,
                        rfc: this.cliente.rfc,
                        ticket: this.ticket,
                        genero: this.cliente.genero,
                        numeroTienda: this.numeroTienda
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            //this.ventanaPago(token.data);
                            this.$snotify.success(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            this.existe = false;
                            this.ticket = {};
                            this.cliente.parentesco = '';
                            this.cliente.nombres = '';
                            this.cliente.apPat = '';
                            this.cliente.apMat = '';
                            this.cliente.celular = '';
                            this.cliente.correo = '';
                            this.cliente.fechaNacimiento = '';
                            this.cliente.rfc = '';
                            this.cliente.genero = '';
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos comenzar tu proceso de pago: ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        ventanaPago(data) {
            var pago = {
                total: data.producto.costo,
                reference: data.referencia,
                cust: {
                    fname: data.cliente.nombres,
                    mname: data.cliente.apPat,
                    lname: data.cliente.apMat,
                    email: data.cliente.correo,
                    phone: "52" + data.cliente.celular
                },
                items: [{
                    name: data.producto.nombreProducto,
                    qty: 1,
                    desc: data.producto.nombreProducto,
                    unitPrice: data.producto.costo
                }],
                recurring: {
                    interval: "month",
                    total: data.producto.costo
                },
            };
            if (data.cliente.ticket.cobroInicial == 0) {
                pago.recurring.start = data.cliente.ticket.fechaCobro;
            }
            var SW = new BwGateway({
                sandbox: backend.sandbox,
                user: backend.usuBw,
                title: "Protección Chedraui",
                concept: data.producto.nombreProducto,
                currency: 'MXN',
                time: 900,
                paymentOptions: 'visa,mastercard',
                reviewOrder: true,
                showShipping: false,
                notifyUrl: backend.urlNotify,
                successPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onSuccess: function (data) {},
                pendingPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onPending: function (data) {},
                challengePage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onChallenge: function (data) {},
                errorPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onError: function (data) {},
                onCancel: function (data) {}
            });
            SW.pay(pago);
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerAssistMovil';
                case 'sm':
                    return 'BannerAssistMovil';
                case 'md':
                    return 'BannerAssist';
                case 'lg':
                    return 'BannerAssist';
                case 'xl':
                    return 'BannerAssist';
            }
        }
    }
}
</script>
